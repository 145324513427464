import React from "react";
import { gql, useQuery } from "@apollo/client";
import spinnerImg from "../../../src/ComponentAssets/images/icos/spinner-ico.svg";

const GET_SOCMED_DATA = gql`
    query SocMed {
        acfOptionsSocialMedia {
            socialMediaSettings {
                socmedHideShow
                socmedFb
                socmedIg
                socmedTwit
            }
        }
    }
`;

export const SocialMediaItem = () => {
    const {loading, error, data} = useQuery(GET_SOCMED_DATA);

    if (loading) return <img className="spinner" src={spinnerImg} alt=""/>;
    if (error) return `Error :(`;
    
    if(data.acfOptionsSocialMedia.socialMediaSettings.socmedHideShow !== 'no'){
        return(
            <div className="vs-icons-group socmed">
    
                <a
                    className="vs--icons-items"
                    href={data.acfOptionsSocialMedia.socialMediaSettings.socmedTwit}
                    rel="noreferrer"
                    target="_blank"
                    aria-label='Twitter'
                >
                    <span className="socmed-twit"></span>
                </a>
                <a
                    className="vs--icons-items"
                    href={data.acfOptionsSocialMedia.socialMediaSettings.socmedFb}
                    rel="noreferrer"
                    target="_blank"
                    aria-label='Facebook'
                >
                    <span className="socmed-fb"></span>
                </a>
                <a
                    className="vs--icons-items"
                    href={data.acfOptionsSocialMedia.socialMediaSettings.socmedIg}
                    rel="noreferrer"
                    target="_blank"
                    aria-label='Instagram'
                >
                    <span className="socmed-ig"></span>
                </a>
            </div>
        )
    }else{
        return null;
    }
}