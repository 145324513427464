import  { useRef, useState, useEffect, useContext } from 'react';
import AuthContext from './AuthProvider';
import '../../ComponentAssets/css/Auth.css';

import axios from '../../api/axios';
const LOGIN_URL = '/auth';

const Login = () => {
    const { setAuth } = useContext(AuthContext);

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    
    useEffect(() => {
        // console.log(userRef.current.focus())
        userRef.current.focus();
    }, []);


    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try{
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({user,pwd}),
                {
                    headers: {
                        'Access-Control-Allow-Headers': '*',
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true
                }
            );
            // console.log(JSON.stringify(response?.data));

            const accessToken = response?.data?.accessToken;
            const roles = response?.data?.roles;

            setAuth({user, pwd, roles, accessToken});
            setUser('');
            setPwd('');
            setSuccess(true);
        } catch(err){
            if(!err?.response){
                setErrMsg('No server Response')
            }else if(err.response?.status === 400){
                setErrMsg('Missing Username or Password')
            }else if(err.response?.status === 401){
                //Unauthorized
                setErrMsg('Kindly check your username of password.')
            }else{
                setErrMsg('Login Failed')
            }
            
            errRef.current.focus();
        }
    }
    
    return (
        <>
            {success ? (
                <Redirect />
            
            ): (
                <div className='login-wrap w12 w-md-4 flex flex-row flex-center'>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <div className='page-header gap-24'>
                        <h1 className='page-title txt-meduim font-Poppins'>Welcome to Our Client Hub!</h1>
                        <span className='page-desc font-merriweather mw-500'>Our client hub page is designed to make it easy for you to access and download all of the game assets you need, including game graphics, sound effects, and animations. We have a wide range of assets available, including different themes, characters, and backgrounds, so you can choose the ones that best suit your needs.</span>
                    </div>
                    <div className='form-wrap w11 w-md-10'>
                        <form className='flex flex-column gap-24' onSubmit={handleSubmit}>
                            <div className='form-group'>
                                <input
                                    type="text"
                                    id="username"
                                    ref={userRef}
                                    autoComplete="off"
                                    onChange={(e) => setUser(e.target.value)}
                                    value={user}
                                    required
                                    placeholder="Username or Email"
                                />
                            </div>
                            <div className='form-group'>
                                <input
                                    type="password"
                                    id="password"
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    required
                                    placeholder="Password"
                                />
                            </div>
                            <div className='form-group btnWrap'>
                                <button className='btn'>login</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    )
}

const Redirect = () => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.replace('https://www.dropbox.com/sh/urujj1y5gqz9sou/AAAD-tKLTdQAtwZjgibhsnNja?dl=0');
        }, 3000);
        return () => clearTimeout(timeout);
    }, []);

    return <>Will redirect in 3 seconds...</>;
}


//
export default Login;