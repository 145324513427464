import React from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import spinnerImg from "../../../src/ComponentAssets/images/icos/spinner-ico.svg";

const GET_FEATURED_POST = gql`
    query FeaturedPost {
        homeSettings {
            homeSettings {
                hpsFrtGrp {
                    hpsFeaturedPost {
                        ... on Game {
                            id
                            slug
                            featuredImage {
                                node {
                                    altText
                                    sourceUrl
                                    slug
                                }
                            }
                        }
                    }
                    hpsShowHideFeaturePost
                }
            }
        }
    }
`;

const HomeFeaturedPost = () => {
    const {loading, error, data} = useQuery(GET_FEATURED_POST);

    const FeaturedCPTFound = Boolean(data?.homeSettings);
    return (
        <>
            {loading ? (
            <img className="spinner" src={spinnerImg} alt=""/>
            ) : error ? (
                <p>Error: {error.message}</p>
            ) : !FeaturedCPTFound ? (
                <p>Post could not be found.</p>
            ) : (
                <FeaturedPostCPT page={data.homeSettings.homeSettings.hpsFrtGrp}/>
            )}
        </>
    )
}

const FeaturedPostCPT = (props) =>{
    const CPTNodes = props.page;
    return (
        <div className="featuredSwiper">
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                initialSlide={0}
                spaceBetween={12}
                slidesPerView={'auto'}
                centeredSlides={true}
                grabCursor={true}
                className="vs-icon-grp vs-tile-swiper"
                navigation={true}
            >
            {
                CPTNodes.hpsFeaturedPost.map((feat, key) => {
                    if(CPTNodes.hpsShowHideFeaturePost !== 'yes'){
                        return(
                            <SwiperSlide key={key}>
                                <Link to={`/games/${feat.slug}`} rel="nofollow" key={key}>
                                    <div className={`vs--icons-item tile tile-${key}`}>
                                        <img
                                            src={feat.featuredImage.node.sourceUrl}
                                            alt={feat.featuredImage.node.altText} 
                                        />
                                    </div>
                                </Link>
                            </SwiperSlide>
                        )
                        
                    }else{
                        return null;
                    }
                })
            }
            </Swiper>
        </div>
    )
}

export default HomeFeaturedPost;