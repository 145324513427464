import React, { Component } from 'react';
import axios from 'axios';

class Form extends Component {

    state = {
        name: '',
        email: '',
        phoneNumber: '',
        message:'',
        sent: false,
        isLoading: false,
        errors:{}
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    formValidation = () => {
        const {name, email} = this.state;
        let isValid = true;
        const errors = {};
        const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const fnameRegEx = /^[a-zA-Z ]*$/;

        if(!name){
            errors.fullNameErr = "Full Name is required!";
            isValid = false;
        }else if(!fnameRegEx.test(name)){
            errors.fullNameErr = "Please input correct full name";
            isValid = false;
        }

        if(!email){
            errors.emailErr = "Email is required!";
            isValid = false;
        }else if(!emailRegEx.test(email)){
            errors.emailErr = "Email provided is invalid";
            isValid = false;
        }

        this.setState({errors});
        return isValid;
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        const isValid = this.formValidation();

        if(isValid){

            const url = '/api/forma';

            let data ={
                name: this.state.name,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                message: this.state.message,
            }
    
            axios.post(url, data)
            .then(res => {
                this.setState({
                    sent: true,
                    isLoading: true
                }, this.handleResetForm())

                setTimeout(() => {
                    this.setState({isLoading: false})
                }, 2000);
            })
            .catch(() => {
                console.log('message not sent')
                this.setState({isLoading: false})
            })

        }else{
            console.log('May Error')
        }
    }

    handleResetForm = () => {
        this.setState({
            name: '',
            email: '',
            phoneNumber: '',
            message:'',
            sent: false
        })
    }



    render() {
        const {name, email,phoneNumber, message,isLoading, errors} = this.state;
        return (
            <>
                <form className="vs--cform" onSubmit={this.handleSubmit}>
                    <div className={`form-group ${errors.fullNameErr ? "error" : ""}`}>
                        <label className="lbl txt-font" htmlFor='nameId'>Full Name<span className="asterisk-clr">*</span></label>
                        <input 
                        type="text" 
                        id='nameId' 
                        name='name' 
                        className='name'
                        value={name}
                        onChange={this.handleChange}/>
                        <span className="error-txt">{errors.fullNameErr}</span>
                    </div>
                    <div className="flex-row">
                        <div className="w12 w-md-6">
                            <div className={`form-group ${errors.emailErr ? "error" : ""}`}>
                                <label className="lbl txt-font" htmlFor='emailId'>Email<span className="asterisk-clr">*</span></label>
                                <input 
                                type="text" 
                                id='emailId' 
                                name='email' 
                                className='email'
                                value={email}
                                onChange={this.handleChange}/>
                                <span className="error-txt">{errors.emailErr}</span>
                            </div>
                        </div>
                        <div className="w12 w-md-6">
                            <div className="form-group">
                                <label className="lbl txt-font" htmlFor='phoneNumberInput'>Phone <span className="txt-option">(optional)</span></label>
                                <input
                                    type="text"
                                    id="phoneNumberInput"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <label className="lbl txt-font" htmlFor='messageId'>Your Message</label>
                        <textarea
                        className='textarea'
                        id='messageId'
                        name='message'
                        value={message}
                        onChange={this.handleChange}
                        ></textarea>
                    </div>
                    <div className="form-group align-start no-margin">
                        <button className="btn" type="submit" disabled={isLoading}>
                            {isLoading && 
                                <svg class="spinner" viewBox="0 0 50 50">
                                    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                                </svg>}
                            {isLoading && 'Loading'}
                            {!isLoading && 'Send Message'}
                        </button>
                    </div>
                </form> 
            </>
        );
    }
}

export default Form;