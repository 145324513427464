import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import spinnerImg from "../../../src/ComponentAssets/images/icos/spinner-ico.svg";

const GET_GAMES_POPULAR_POST = gql`
    query GamesPopularPost {
        acfOptionsGameSettings {
            popular {
                grpPpg {
                    ppgHideShowSection
                    ppgSelection {
                        ... on Game {
                            id
                            slug
                            title
                            featuredImage {
                                node {
                                    altText
                                    sourceUrl
                                }
                            }
                        }
                    }
                    fieldGroupName
                }
            }
        }
    }
`;

const GamesPopularPost = () => {
    const {loading, error, data} = useQuery(GET_GAMES_POPULAR_POST);

    const PopularPostFound = Boolean(data?.acfOptionsGameSettings);
    return (
        <>
            {loading ? (
            <p><img className="spinner" src={spinnerImg} alt=""/></p>
            ) : error ? (
                <p>Error: {error.message}</p>
            ) : !PopularPostFound ? (
                <p>Post could not be found.</p>
            ) : (
                <CPTPPost page={data.acfOptionsGameSettings.popular}/>
            )}
        </>
    )
}

const CPTPPost = (props) => {
    const CPTNodes = props.page;
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        window.addEventListener('resize', () => {
            const ismobile = window.innerWidth < 768;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false)
    }, [isMobile]);

    if(CPTNodes.grpPpg.ppgHideShowSection !== 'yes'){
        return (
            <div className="popularpost games-list swiper--coat">
                {isMobile ? <GamesMobileSlider postGames={CPTNodes}/> : <GamesTabletToDesktopSlider postGames={CPTNodes}/>}
            </div>
        )
    }else{
        return 'No Item Selected';
    }
}

const GamesTabletToDesktopSlider = (postGames) => {

    const popularGroup = postGames.postGames.grpPpg;
    return (
        <>
            <div className="Big-wrap">

                {
                    popularGroup.ppgSelection.map((feat, key) => {
                        if(key === 0){
                            return (
                                <Link to={`/games/${feat.slug}`} className="big-item" key={key}>
                                    <div className="tiles big-tiles">
                                        <img
                                            src={feat.featuredImage.node.sourceUrl}
                                            alt={feat.featuredImage.node.altText} 
                                            key={key}
                                        />
                                    </div>
                                </Link>
                            )
                        }else{
                            return null;
                        }
                    })
                }
            </div>


            <div className="Small-wrap">
            {
                popularGroup.ppgSelection.map((feat, key) => {
                    if(key > 0 && key < 9){
                        return (
                            <Link to={`/games/${feat.slug}`} className={`small-${key} small--item`} rel="nofollow" key={key}>
                                <div className="tiles small-tiles">
                                    <img
                                        src={feat.featuredImage.node.sourceUrl}
                                        alt={feat.featuredImage.node.altText} 
                                        key={key}
                                    />
                                </div>
                            </Link>
                            
                        )
                    }else{
                        return null;
                    }
                })
            }
            </div>
            
        </>
    )
}

const GamesMobileSlider = (postGames) => {

    const popularGroup = postGames.postGames.grpPpg;
    return (
        <>
            <Swiper
                modules={[Navigation, Pagination,  A11y]}
                spaceBetween={8}
                slidesPerView={"auto"}
                className="vs-icon-grp vs-tile-swiper"
                navigation={true}
            >
                {
                    popularGroup.ppgSelection.map((feat, key) => {
                        return (
                            <SwiperSlide key={key}>
                                <Link to={`/games/${feat.slug}`} rel="nofollow">
                                    <div className="tiles big-tiles">
                                        <img
                                            src={feat.featuredImage.node.sourceUrl}
                                            alt={feat.featuredImage.node.altText} 
                                            key={key}
                                        />
                                    </div>
                                </Link>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </>
    )
}

export default GamesPopularPost;