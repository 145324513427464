import React from "react";
import { Route, Routes } from "react-router-dom";
import { from, gql, useQuery } from "@apollo/client";

// import Form from "./utils/Form";
import NavMenu from "./Components/NavigationComponent/NavMenu";
import HomePage from "./Pages/Home/Home";
import GamePage from "./Pages/Games/GamePage";
import GameSlugContent from "./Components/GamesCPTComponent/GamesSlugContent";
import FooterComponent from "./Components/FooterComponent/Footer";
// import Login from "./Components/Auth/Login";
import Login from "./Components/Auth/LoginForm";
import ReactGA from "react-ga4";
import MaintenanceComponent from "./Components/MaintenanceComponent/maintenance";
import spinnerImg from "./ComponentAssets/images/icos/spinner-ico.svg";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";

// const loader = document.querySelector('.pre-loader');
// const showLoader = () => loader.classList.remove("pre-loader");
// const loaderAddClass = () => loader.classList.add('d-none');

const GET_MAINTENANCE_QUERY = gql `
    query Maintenance {
        acfOptionsMaintenanceSettings {
            maintenance {
                mainDesc
                mainEnabled
                mainFooterText
                mainPageTitle
                mainHeadline
            }
        }
    }
`;

const MainComponent = () => {
    const {loading, error, data} = useQuery(GET_MAINTENANCE_QUERY);
    const MaintenanceFound = Boolean(data?.acfOptionsMaintenanceSettings.maintenance);

    if(loading) return <p className="spinnerImg"><img className="spinner" src={spinnerImg} alt=""/><br/><br/>Loading...</p>;
    if(error) return <p>Error</p>;

    const onClick = () => {
        ReactGA.event({
            action: 'app_action',
            category: 'app_category',
            label: 'app_label',
            value: 'xxxx',
        })
      }
    return (
        <>
            { data.acfOptionsMaintenanceSettings.maintenance.mainEnabled !== null ? (
                <MaintenanceComponent data={data.acfOptionsMaintenanceSettings.maintenance} />
            ) : (
                <>
                    <header className="header-wrap">
                        <NavMenu component={NavMenu} />
                    </header>
                    <main className="main-wrap">
                        <ScrollToTop />
                        <Routes>
                            <Route onClick={onClick} path="/" element={<HomePage />} />
                            <Route onClick={onClick} path="/games" element={<GamePage />} />
                            <Route onClick={onClick} path="/games/:slug" element={<GameSlugContent />} />
                            <Route onClick={onClick} path="/login" element={<Login />} />
                            
                            {/* <Route path="/about-page" element={<AboutPage />} /> */}
                            {/* <Route path="/news" element={<NewsPage/>} /> */}
                            {/* <Route path="/news/:slug" element={<PostPage />} /> */}
                        </Routes>
                    </main>
                    <footer className="footer-wrap d-mw-default">
                        <FooterComponent />
                    </footer>
                </>
            ) }
        </>
    )
}

export default MainComponent;