import React from "react";
import { useNavigate } from "react-router-dom";
import {gql, useQuery} from '@apollo/client';
import HomeFeaturedPost from "./HomeFeaturedPost";
import bannerCharacter from "../../../src/ComponentAssets/images/banner/main-character.png";
import bannerMountain from "../../../src/ComponentAssets/images/banner/main-banner-bg.png";
import bannerStar from "../../../src/ComponentAssets/images/banner/main-star.png";
import {} from "../../../src/ComponentAssets/images/banner/main-wave.png";
import spinnerImg from "../../../src/ComponentAssets/images/icos/spinner-ico.svg";
import ReactGA from "react-ga4";

const onClick = () => {
    ReactGA.event({
        action: 'hero_banner',
        category: 'hero_category',
        label: 'hero_label',
        value: 'xxxx',
    })
}

const GET_HOME_QUERY = gql `
    query HomePage {
        homeSettings {
            homeSettings {
                hpsFrtGrp {
                    hpsTitle
                    hpsContent
                    hpsId
                    hpsShowHideBtnSect
                    hpsShowHideButtons
                    hpsShowHideCurve
                    hpsShowHideFeaturePost
                    hpsBgImg {
                      sourceUrl
                    }
                }
            }
        }
    }
`;

const HeroBanner = () => {

    const {loading, error, data} = useQuery(GET_HOME_QUERY);

    const HomePageFound = Boolean(data?.homeSettings.homeSettings.hpsFrtGrp);
    if (loading) return <p className="spinnerImg"><img className="spinner" src={spinnerImg} alt=""/><br/><br/>Loading...</p>;
    if (error) return <p>Error :(</p>;
    if (!HomePageFound) return <p>Section not be found.</p>;

    return (
        <>
            <HeroBannerContent hero={data.homeSettings.homeSettings.hpsFrtGrp}/>
        </>
    )

}


const HeroBannerContent = (props) => {
    const navigate = useNavigate();

    const bgImg = (props.hero.hpsBgImg.sourceUrl) ? props.hero.hpsBgImg.sourceUrl : '';
    const btnShowHide = props.hero.hpsShowHideButtons;
    let letStartBtn;
    let ourGamesBtn;

    if(btnShowHide === 'yes'){
        letStartBtn = <button  onClick={(e) => {
            e.preventDefault();
            window.location.href='/#contact';
            }} className="btn" >
                Get in touch
            </button>;
        ourGamesBtn = <button onClick={() => navigate("/games") } className="btn btn-transparent" >Discover our games!</button>;
    }

    if(props.hero.hpsShowHideBtnSect !== 'yes'){
        const bgImgStyle = { background: `url(${bgImg})` }
        const titleWords = props.hero.hpsTitle.split(" ");
        const fourthToSixthWord = titleWords.slice(3, 6).join(" ");
        const coloredTitle = `${titleWords.slice(0, 3).join(" ")} <span style="color: #E31F2E;">${fourthToSixthWord}</span> ${titleWords.slice(6).join(" ")}`;
        return (
            <section id={props.hero.hpsId} className="banner" >
                <div className="inner flex">
                    <div className="vs--inner-content w12 w-md-6">
                        {/* <h3 className="vs--hero-title" dangerouslySetInnerHTML={{__html: props.hero.hpsTitle}} /> */}
                        <h3 className="vs--hero-title" dangerouslySetInnerHTML={{__html: coloredTitle }} />
                        <div
                            className="vs--hero-desc"
                            dangerouslySetInnerHTML={{__html: props.hero.hpsContent}}
                        />
                        <div className="vs--inner-btn-wrp flex">
                            {letStartBtn}
                            {ourGamesBtn}
                        </div>
                    </div>
                    <div className="vs--inner-img w12 w-md-6 relative">
                        <div className="featured-post-wrap w12 absolute">
                            <span className="txt bold txt-upper txt-white mar-top-r">Featured Games</span>
                            <HomeFeaturedPost />           
                        </div>
                    </div>
                    <div className="parallax-effect p-relative" id="scene">
                        <div className="parallax-child c-absolute">
                            <div id="stars-group-1" className="sparkle1"></div>
                            <div id="stars-group-1" className="sparkle2"></div>
                            <div class="meteor-1"></div>
                            <div class="meteor-2"></div>
                            <div class="meteor-3"></div>
                            <div class="meteor-4"></div>
                            <div class="meteor-5"></div>
                            <img src={bannerStar} alt="star" />    
                        </div>
                        <div className="parallax-child c-absolute">
                            <img src={bannerCharacter} alt="logo" />    
                        </div>
                        <div className="parallax-child c-absolute">
                            <img src={bannerMountain} alt="Mountain Banner" />
                        </div>
                    </div>
                </div>
            </section>
        )
    }else{
        return null;
    }
}

// const BGSt = (props) =>{
//     const bgImgStyle = {
//         backgroundImage: `url(${props.img})`,
//         backgroundRepeat: 'no-repeat',
//         backgroundSize: 'cover',
//         zIndex: -1,
//     }
//     return(
//         <div className="bg" style={bgImgStyle}></div>
//     )
// }

export default HeroBanner;