import React, {useEffect} from "react";
import {} from '../../ComponentAssets/css/Maintenance.css';
import LightLogo from '../../ComponentAssets/images/Logo/logo-light.png';
import astro from '../../ComponentAssets/images/Maintenance/astro.svg';

// const MaintenanceComponent = (props) => {
//     console.log(props)

//     useEffect(() => {
//         document.body.classList.add('maintenance-template');
//         return () => {
//             document.body.classList.add('maintenance-template');
//         }
//     }, [])

//     return (
//         <section className="maintenance-section">
//             <div className="inner">
//                 <div className="maintenance-header">
//                     <img src={LightLogo} />
//                 </div>
//                 <div className="maintenance-content">
//                     <p className="page-title" dangerouslySetInnerHTML={{__html:props.data.mainHeadline}} />
//                     <img src={astro} />
//                     <div dangerouslySetInnerHTML={{__html:props.data.mainDesc}} />
//                 </div>
//                 <div className="maintenance-footer">
//                     <div className="w6">
//                         <img src={LightLogo} />
//                     </div>
//                     <div className="w6">
//                         <div dangerouslySetInnerHTML={{__html:props.data.mainFooterText}} />
//                     </div>
//                 </div>
//             </div>
//         </section>
//     )
// }

const MaintenanceComponent = (props) => {
    useEffect(() => {
        document.body.classList.add('maintenance-template');
        return () => {
            document.body.classList.add('maintenance-template');
        }
    }, [])

    return (
        <section className="maintenance-section">
            <div className="inner">
                <div className="maintenance-header">
                    <img src={LightLogo} />
                </div>
                <div className="maintenance-content">
                    <p className="page-title" dangerouslySetInnerHTML={{__html:props.data.mainHeadline}} />
                    <img src={astro} />
                    <div className="page-desc flex flex-center" dangerouslySetInnerHTML={{__html: props.data.mainDesc.replace('<a href="mailto:info@vivagamestudio.com">info@vivagamestudio.com</a>', '<a class="link link--metis" href="mailto:info@vivagamestudio.com"><span  style="color: #E31F2E;" >info@vivagamestudio.com</span></a>') }} />
                </div>
                <div className="maintenance-footer">
                    <div className="w6">
                        <img src={LightLogo} />
                    </div>
                    <div className="w6">
                        <p dangerouslySetInnerHTML={{__html:props.data.mainFooterText}} />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default MaintenanceComponent;