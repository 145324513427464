import React, {useState, useEffect} from 'react';
import { gql, useQuery } from "@apollo/client";
import tradingBetSm from '../../ComponentAssets/images/Home/trading-bet/laptop-sm-u.png';

const GET_BANNER_DATA = gql`
    query Footer {
        acfOptionsBannerSettings {
            bannerSettings {
                bannerGrps {
                    bannerContent
                    bannerDesc
                    bannerId
                    bannerImg {
                        altText
                        sourceUrl
                        uri
                        slug
                    }
                    bannerShowHideButton
                    bannerShowHideSections
                    bannerTitle
                    fieldGroupName
                }
            }
        }
    }
`;

const BannerSection = () => {
    
    const {loading, error, data} = useQuery(GET_BANNER_DATA);
    const BannerFound = Boolean(data?.acfOptionsBannerSettings.bannerSettings.bannerGrps);

    return (
        <>
            {loading ? (
            <p className='d-none'>Loading…</p>
            ) : error ? (
                <p>Error: {error.message}</p>
            ) : !BannerFound ? (
                <p>Section not be found.</p>
            ) : (
                <BannerSecLayout section={data.acfOptionsBannerSettings.bannerSettings.bannerGrps}/>
            )}
        </>
    )
}

const BannerSecLayout = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1023);
    useEffect(() => {
        window.addEventListener('resize', () => {
            const ismobile = window.innerWidth < 1023;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false)
    }, [isMobile]);

    if(props.section.bannerShowHideSections !== 'yes'){
        return (
            <section className="banner-section vs-home-sec" id={props.section.bannerId}>
                <div className="inner">
                    <div className='trading--coat trading--mb trading--tab trading--desk flex align-center justify-between'>
                        <div className='w12 w-md-4 txt-center'>
                            <img 
                            
                                src={isMobile ? tradingBetSm : props.section.bannerImg.sourceUrl}
                                alt='trading bet'/>
                        </div>
                        <div className='w12 w-md-7'>
                            <SectionHeader title={props.section.bannerTitle} desc={props.section.bannerDesc}/>
                            <SectionContent content={props.section.bannerContent}/>
                        </div>
                    </div>
                </div>
            </section>
        )
    }else{
        return null;
    }
}


const SectionHeader = (props) => {
    if(props.title !== null && props.desc.length > 0){
        return (
            <div className="vs-sec-header align-start">
                {
                    props.title ? (
                        <h1 className="flex flex-center sec-title txt-upper txt-white">{props.title}</h1>
                    ) : null
                }
                {
                    props.desc ? (
                        <span className="vs-sec-desc flex flex-center txt-2e6" dangerouslySetInnerHTML={{__html:props.desc}} />
                    ) : null
                }
            </div>
        )
    }
}

const SectionContent = (content) => {
    // console.log(content)
    return (
        <>
            <div className='vs-sec-content' dangerouslySetInnerHTML={{__html:content.content}} />
        </>
    )
}

export default BannerSection;