import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
// import Device from '../../ComponentAssets/images/Home/device.png';
// import Phone from '../../ComponentAssets/images/Home/phone_set/mobile_phone.png';
import PhoneVideo from '../../ComponentAssets/images/Home/phone_set/phone_vide.mp4';
import {} from "../../../src/ComponentAssets/js/anim.js";

import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';


const GET_THIRD_QUERY = gql`
    query HomePage {
        homeSettings {
            homeSettings {
                hpsThrdGrp {
                    fieldGroupName
                    hpsThrdContent
                    hpsThrdId
                    hpsThrdShowHideBtnSect
                    hpsThrdShowHideButtons
                    hpsThrdTitle
                    hpsThrdImg {
                        altText
                        sourceUrl
                    }
                    hpsThrdIconsGrp {
                        fieldGroupName
                        hpsIconsLabel
                        hpsIconsImg {
                            altText
                            sourceUrl
                        }
                    }
                }
            }
        }
    }
`;

const TechSection = () => {
    const {loading, error, data} = useQuery(GET_THIRD_QUERY);
    const HomeThirdFound = Boolean(data?.homeSettings.homeSettings.hpsThrdGrp);

    return (
        <>
            {loading ? (
            <p className="d-none">Loading…</p>
            ) : error ? (
                <p>Error: {error.message}</p>
            ) : !HomeThirdFound ? (
                <p>Section not be found.</p>
            ) : (
                <ThirdSecLayout section={data.homeSettings.homeSettings.hpsThrdGrp}/>
            )}
        </>
    )
}

const ThirdSecLayout = (props) =>{
    if(props.section.hpsThrdShowHideBtnSect !== 'yes'){
        return (
            <section className="third-section vs-home-sec" id={props.section.hpsSecId}>
                <div className="inner">
                    <SectionHeader title={props.section.hpsThrdTitle} desc={props.section.hpsThrdContent}/>
                    <SectionContent key={props} image={props.section.hpsThrdImg} icon={props.section.hpsThrdIconsGrp} button={props.section.hpsThrdShowHideButtons}/>
                </div>
            </section>
        )
    }else{
        return null;
    }
}

// const SectionHeader = (props) => {
//     if(props.title !== null && props.desc !== null){
//         return (
//             <div className="vs-sec-header">
//                 {
//                     props.title ? (
//                         <h1 className="flex flex-center sec-title txt-upper">{props.title}</h1>
//                     ) : null
//                 }
//                 {
//                     props.desc ? (
//                         <span className="vs-sec-desc flex flex-center hide-text" 
//                         dangerouslySetInnerHTML={{__html:props.desc}} />
//                     ) : null
//                 }
//             </div>
//         )
//     }
// }

const SectionHeader = (props) => {
    const firstTwoWords = props.title.split(' ').slice(0, 2).join(' ');
    const restOfTitle = props.title.substring(firstTwoWords.length);
    if(props.title !== null && props.desc !== null){
        return (
            <div className="vs-sec-header">
                {
                    props.title ? (
                        // <h1 className="flex flex-center sec-title txt-upper">{props.title}</h1>
                        <h1 className="flex flex-center sec-title txt-upper dpblck"><span style={{color: '#E31F2E'}}>{firstTwoWords}</span>{restOfTitle}</h1>
                    ) : null
                }
                {
                    props.desc ? (
                        <span className="vs-sec-desc flex flex-center hide-text"
                            dangerouslySetInnerHTML={{
                                __html: props.desc.replace(/57 GAMES/g, '<span class="color-text" style="color: #E31F2E;>57 GAMES</span>')
                            }} />
                    ) : null
                }
            </div>
        )
    }
}

const SectionContent = ({image, icon, button}) => {
    // let btnWrp = ( button === 'yes') ? <SectionBTN /> : '';
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    
    useEffect(() => {
        window.addEventListener('resize', () => {
            const ismobile = window.innerWidth < 768;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false)
    }, [isMobile]);

    return(
        <> 
            <div className="vs-sec-content flex flex-center m-b-66">
                { (isMobile) ? (
                    <>
                        <div className="vs--content-three vs--content flex-center">
                            <div className="vs-icon-grp">
                                    {
                                        icon.map((icon, key) => {
                                            return(
                                                <div className="vs--icons-item" key={key}>
                                                    <Icons icon={icon}/>
                                                </div>
                                            )
                                        })
                                    }
                            </div>
                        </div>
                        {/* controls={['PlayPause']} */}
                        <Video className="mobile-video" playsInline autoPlay loop muted>
                            <source src={PhoneVideo} type="video/mp4" />
                        </Video>
                    </>
                ) : (
                    <>
                        <div className="vs--content-one vs--content flex-center">
                            <div className="vs-icon-grp">
                                {
                                    icon.map((icon, key) => {
                                        if(key <= 3){
                                            return(
                                                <div className="vs--icons-item" key={key}>
                                                    <Icons icon={icon}/>
                                                </div>
                                            )
                                        }else{
                                            return '';
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className="vs--content-two vs--content flex-center flex-column">
                            <div className="phoneSet">
                                <div className="phoneVideo">
                                    <Video autoPlay loop muted controls={['PlayPause']}>
                                        <source src={PhoneVideo} type="video/mp4" />
                                    </Video>
                                </div>
                            </div>
                        </div>
                        <div className="vs--content-three vs--content flex-center">
                            <div className="vs-icon-grp">
                                    {
                                        icon.map((icon, key) => {
                                            if(key >= 4){
                                                return(
                                                    <div className="vs--icons-item" key={key}>
                                                        <Icons icon={icon}/>
                                                    </div>
                                                )
                                            }else{
                                                return '';
                                            }
                                            
                                        })
                                    }
                            </div>
                        </div>
                    </>
                ) }
            </div>
            <div className="btnWrap">
                {/* {btnWrp} */}
                <a className="btn" href="#contact">Get Started</a>
            </div>
        </>
    )
}

const Icons = (icon) => {
    return(
        <>
            <div className="vs--icons-img circle">
                { icon.icon.hpsIconsImg ? (
                    <img
                        src={icon.icon.hpsIconsImg.sourceUrl}
                        alt={icon.icon.hpsIconsImg.altText}
                    />
                ) : null}
            </div>
            <span className="vs--icons-lbl lbl">{icon.icon.hpsIconsLabel}</span>
        </>
    )
}

const SectionBTN = () => {
    return (
        <>
            <Link className="btn" to={`/#contact`} rel='nofollow'>Get Started</Link>
        </>
    )
}

export default TechSection;
