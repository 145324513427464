import React from "react";
import {Helmet} from 'react-helmet';

const Heading = ({title}) => {
    return (
        <Helmet>
            <title>VIVA GAME STUDIO {title}</title>
        </Helmet>
    )
}
export default Heading;