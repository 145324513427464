import React, { useEffect} from "react";
import { gql, useQuery } from "@apollo/client";
import PageHeader from "../../Components/PostPageComponent/PagesPost/PageHeader";
import AllGamesList from "../../Components/GamesCPTComponent/AllGamesList";
import GamesPopularPost from "../../Components/GamesCPTComponent/GamesPopularPost";
import LightLogo from '../../ComponentAssets/images/Logo/logo-light.png';
import DarkLogo from '../../ComponentAssets/images/Logo/logo-dark.png';
import BannerSection from "../../Components/HomeComponent/BannerSection";
import spinnerImg from "../../../src/ComponentAssets/images/icos/spinner-ico.svg";
import Heading from "../../Components/Heading/Heading";


/**
 * Local: cG9zdDoyMQ==
 * Live: cG9zdDoxODM4
 */

const GET_NEWSPAGE_BY_ID = gql`
    query MyPage{
        page(id: "cG9zdDoxODM4") {
            title
            content
            subTitle {
                title1
                title2
            }
        }
    }
`;

const GamePage = () => {

    useEffect(() => {
        document.body.classList.add('game-template', 'game-page')
        return () => {
            document.body.classList.remove('game-template', 'game-page')
        }
    }, []);

    const {loading, error, data} = useQuery(GET_NEWSPAGE_BY_ID);
    
    const gamesPageFound = Boolean(data?.page);

    return (
        <section className="Game">
            <Heading title="| Games - The most engaging and reliable games!" />
            <div className="inner">
                {loading ? (
                <p className="spinnerImg"><img className="spinner" src={spinnerImg} alt=""/><br/><br/>Loading...</p>
                ) : error ? (
                    <p>Error: {error.message}</p>
                ) : !gamesPageFound ? (
                    <p>Post could not be found.</p>
                ) : (
                    <ContentGamesPage page={data.page}/>
                )}
            </div>
        </section>
    )
}

const ContentGamesPage = ({ page }) => {
    const {
      title,
      content,
      subTitle
    } = page;

    return (
        <>
            <PageHeader title={title} content={content}/>
            <GamesPopular title={subTitle.title1}/>
            <BannerSection />
            <AllGames title={subTitle.title2}/>
        </>
    )
}

const GamesPopular = (props) => {
    const {
        title
    } = props;
    return(
        <div className="games-popular">
            <div className="sub-page-header">
                <h1 className="sub-page-title">{title}</h1>
            </div>
            <GamesPopularPost />
        </div>
    )
}

const AllGames = (props) => {
    const {
        title
    } = props;
    return(
        <div className="all-games">
            <div className="sub-page-header">
                <h1 className="sub-page-title">{title}</h1>
            </div>
            <AllGamesList />
        </div>
    )

}

export default GamePage;