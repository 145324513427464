import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import spinnerImg from "../../../src/ComponentAssets/images/icos/spinner-ico.svg";

const GET_ALL_GAMES = gql`
  query AllGames {
    games(where: { orderby: { field: TITLE, order: ASC } }, first: 100) {
      nodes {
        databaseId
        title
        slug
        featuredImage {
          node {
            altText
            slug
            title
            sourceUrl
          }
        }
      }
    }
  }
`;

const AllGamesList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { loading, error, data } = useQuery(GET_ALL_GAMES);

  if (loading) return <img className="spinner" src={spinnerImg} alt="" />;
  if (error) return <p>Error :(</p>;

  const AllgamesFound = Boolean(data?.games.nodes.length);

  if (!AllgamesFound) {
    return <p>No Matching All Games found.</p>;
  }

  const filteredGames = data.games.nodes.filter((game) =>
    game.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="search-container">
        <input
          type="text"
          placeholder="e.g. Arctic Sky..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="neumorphic-input"
        />
      </div>
      <div className="games-list">
        {filteredGames.map((allgames) => (
          <AllGamesCard key={allgames.databaseId} gameList={allgames} />
        ))}
      </div>
    </>
  );
};

const AllGamesCard = ({ gameList }) => {
  const { slug, featuredImage } = gameList;
  return (
    <div className="all-games-card">
      <Link to={`/games/${slug}`} rel="nofollow">
        {featuredImage ? (
          <img src={featuredImage.node.sourceUrl} alt={featuredImage.node.altText} />
        ) : null}
      </Link>
    </div>
  );
};

export default AllGamesList;
