import React from "react";
import { gql, useQuery } from "@apollo/client";
import { SocialMediaItem } from "../SocialMedia/SocialMediaItem";
import DarkLogo from '../../ComponentAssets/images/Logo/logo-dark.png';
import spinnerImg from "../../../src/ComponentAssets/images/icos/spinner-ico.svg";

const GET_FOOTER_DATA = gql`
    query Footer {
        acfOptionsFooterSettings {
            footerSettings {
                footerSettings {
                    footerHideShow
                    footerTnc
                    fieldGroupName
                }
            }
        }
    }
`;
 
const Footer = () => {
    const {loading, error, data} = useQuery(GET_FOOTER_DATA);

    // if (loading) return <p><img className="spinner" src={spinnerImg} alt=""/></p>;
    if (loading) return <p className="d-none">loading</p>;
    if (error) return <p>Error :(</p>;
    const FooterFound = Boolean(data?.acfOptionsFooterSettings.footerSettings.footerSettings);

    if( !FooterFound ){
        return <p>No Terms & Condition found.</p>
    }

    return (
        <div className="inner">
            <div className="flex footer-socket">
                <div className="w12 w-md-6">
                    <div className="vs--footer-group flex">
                        <img src={DarkLogo} alt="viva logo"/>
                        {/* <div className="vs--footer-item w12 w-md-4">
                            <span className="txt bold txt-upper">LEARN MORE</span>
                            <ul className="widget">
                                <li>About VIVA</li>
                                <li>Developers Docs</li>
                                <li>Download</li>
                                <li>Documentation</li>
                            </ul>
                        </div>
                        <div className="vs--footer-item w12 w-md-4">
                            <span className="txt bold txt-upper">CONNECT</span>
                            <ul className="widget">
                                <li>FAQs</li>
                                <li>Support</li>
                                <li>News</li>
                                <li>Twitter</li>
                                <li>Instagram</li>
                            </ul>
                        </div>
                        <div className="vs--footer-item w12 w-md-4">
                            <span className="txt bold txt-upper">LEGAL</span>
                            <ul className="widget">
                                <li>Privacy Policy</li>
                                <li>Term of Use</li>
                                <li>Contributor License Agreement</li>
                                <li>Site Map</li>
                            </ul>
                        </div> */}
                    </div>
                </div>
                <div className="w12 w-md-6 w-460">
                    <div className="widget">
                        <div className="socmed-wrap">
                            {/* <span className="txt bold">Find us here: </span> */}
                            <SocialMediaItem />
                        </div>
                        <FooterTerms props={data.acfOptionsFooterSettings.footerSettings.footerSettings}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FooterTerms = (props) => {
    if(props.footerHideShow !== 'yes'){
        const content = (props.props.footerTnc !== null) ? props.props.footerTnc : '';
        return (
            <div className="widget-content">
                <p className="txt font-merri txt-right" dangerouslySetInnerHTML={{__html:content}} />
            </div>
        )
    }else{
        return null;
    }
}

export default Footer;