import React, { useEffect } from "react";
import {} from '../../ComponentAssets/css/HomePage.css';
import HeroBanner from "../../Components/HomeComponent/HeroBanner";
import VivaStudioSection from "../../Components/HomeComponent/VivaStudioSection";
import TechSection from "../../Components/HomeComponent/TechSection";
import GameStudTechSection from "../../Components/HomeComponent/GameStudTechSection";
import ProductSuiteSection from "../../Components/HomeComponent/ProductSuiteSection";
import NewsSection from "../../Components/HomeComponent/NewsSection";
import BannerSection from "../../Components/HomeComponent/BannerSection";
import ContactSection from "../../Components/HomeComponent/ContactSection";
import BrandSection from "../../Components/HomeComponent/BrandSection";
import Heading from "../../Components/Heading/Heading";

const Home = () =>{

    useEffect(() => {
        document.body.classList.add('home-template', 'home-page');
        document.body.classList.remove('maintenance-template');
        return () => {
            document.body.classList.remove('home-template', 'home-page');
            document.body.classList.remove('maintenance-template');
        }
    }, []);
    return (
        <>
            <Heading title={' - The most engaging and reliable games! Get ready for a thrilling gaming experience with our cutting-edge slot games and technology.'} />
            <HeroBanner />
            <VivaStudioSection />
            <TechSection />
            <GameStudTechSection />
            <ProductSuiteSection />
            <BannerSection />
            <NewsSection />
            <ContactSection />
            <BrandSection />
        </>
    )
}

export default Home;