import React from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import ContactForm from "../../utils/ContactForm";
// import { SocialMediaItem } from "../SocialMedia/SocialMediaItem";
// import contactImg from "../../ComponentAssets/images/Home/contact/contact.png";

const GET_SEVEN_QUERY = gql `
    query HomePage {
        homeSettings {
            homeSettings {
                hpsSvnGrp {
                  fieldGroupName
                  hpsSvnId
                  hpsSvnShowHideBtnSect
                  hpsSvnTitle
                  hpsSvnContent
                  hpsSvnEmail
                  hpsSvnPhone
                  hpsSvnAddress
                }
            }
        }
    }
`;

const ContactSection = () => {
    const {loading, error, data} = useQuery(GET_SEVEN_QUERY);
    const HomeSevenFound = Boolean(data?.homeSettings.homeSettings.hpsSvnGrp);

    return (
        <>
            {loading ? (
            <p className="d-none">Loading…</p>
            ) : error ? (
                <p>Error: {error.message}</p>
            ) : !HomeSevenFound ? (
                <p>Section not be found.</p>
            ) : (
                <SevenSecLayout section={data.homeSettings.homeSettings.hpsSvnGrp}/>
            )}
        </>
    )
}

// const SevenSecLayout = (props) => {
//     if(props.section.hpsSvnShowHideBtnSect !== 'yes'){
//         return (
//             <section className="seven-section vs-home-sec" id={props.section.hpsSvnId}>
//                 <div className="inner">
//                     <SectionHeader title={props.section.hpsSvnTitle} desc={props.section.hpsSvnContent} />
//                     <SectionContent key={props} phone={props.section.hpsSvnPhone} address={props.section.hpsSvnAddress} email={props.section.hpsSvnEmail}/>
//                     <div className="btnWrap">
//                         {/* <a className="btn" href="mailto:info@vivagamestudio.com">Contact Us</a> */}
//                         <Link 
//                             className="btn" 
//                             to='#'
//                             onClick={(e) => {
//                                 window.location.href = 'mailto:info@vivagamestudio.com';
//                                 e.preventDefault();
//                             }}
//                             rel='nofollow'
//                             >Contact Us</Link>
//                     </div>
//                 </div>
//             </section>
//         )
//     }else{
//         return null;
//     }
// }

const SevenSecLayout = (props) => {
    if (props.section.hpsSvnShowHideBtnSect !== 'yes') {
      const title = props.section.hpsSvnTitle;
      const titleWords = title.split(' ');
      titleWords[9] = '<span style="color: #E31F2E">' + titleWords[9] + '</span>';
      titleWords[10] = '<span style="color: #E31F2E">' + titleWords[10] + '</span>';
      const formattedTitle = titleWords.join(' ');
  
      return (
        <section className="seven-section vs-home-sec" id={props.section.hpsSvnId}>
          <div className="inner">
            <SectionHeader
              title={<span dangerouslySetInnerHTML={{ __html: formattedTitle }} />}
              desc={props.section.hpsSvnContent}
            />
            <SectionContent key={props} phone={props.section.hpsSvnPhone} address={props.section.hpsSvnAddress} email={props.section.hpsSvnEmail} />
            <div className="btnWrap">
              <Link className="btn" to="#" onClick={(e) => {
                window.location.href = 'mailto:info@vivagamestudio.com';
                e.preventDefault();
              }} rel="nofollow">Contact Us</Link>
            </div>
          </div>
        </section>
      );
    } else {
      return null;
    }
  };

const SectionHeader = (props) => {
    if(props.title !== null && props.desc.length > 0){
        return (
            <div className="vs-sec-header">
            {
                props.title ? (
                    <h1 className="flex flex-center sec-title txt-upper mw-md">{props.title}</h1>
                ) : null
            }
            {
                props.desc ? (
                    <span className="vs-sec-desc flex flex-center hide-text" 
                    dangerouslySetInnerHTML={{__html:props.desc}} />
                ) : null
            }
            </div>
        )
    }
}

const SectionContent = ({ phone, address, email }) => {
    return(
        <div className="flex nw--contact">
            <div className="contact-group w12 w-md-4">
                <div className="contact-item flex flex-column">
                    <span className="txt-lbl lbl-font">Phone</span>
                    <span className="txt">{phone}</span>
                </div>
                <div className="contact-item flex flex-column">
                    <span className="txt-lbl lbl-font">E-mail</span>
                    <span className="txt">{email}</span>
                </div>
                <div className="contact-item flex flex-column">
                    <span className="txt-lbl lbl-font">Our Office </span>
                    <span className="txt">{address}</span>
                </div>
                {/* <div className="contact-item flex flex-column">
                    <span className="txt-lbl lbl-font sm-txt-footer">Find us here: </span>
                    <SocialMediaItem />
                </div> */}
            </div>
            <div className="bg-contact"><ContactForm /></div>
            {/* <div className="w12 w-md-8">
                <img src={contactImg} alt="" />
            </div> */}
        </div>
    )
}

export default ContactSection;