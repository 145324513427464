import { useState, useEffect } from "react";
import { Link, NavLink, useMatch, useResolvedPath, useLocation } from 'react-router-dom';
import { } from '../../ComponentAssets/css/MenuStyle.css';
import { HashLink } from 'react-router-hash-link';
import LightLogo from '../../ComponentAssets/images/Logo/logo-light.svg';
import DarkLogo from '../../ComponentAssets/images/Logo/logo-dark.svg';
import { } from "../../../src/ComponentAssets/js/navBar.js";


const NavBrand = (isNavSticky) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const stickyMenuActive = isNavSticky.stickMenu;
    let newImgSrc = LightLogo;
    const location = useLocation();

    useEffect(() => {
        window.addEventListener('resize', () => {
            const ismobile = window.innerWidth < 768;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false)
    }, [isMobile]);

    if(location.pathname !== '/' && !stickyMenuActive){
        newImgSrc = DarkLogo;
    }else if(location.pathname !== '/' && stickyMenuActive){
        newImgSrc = LightLogo;
    }else{
        newImgSrc = LightLogo;
    }

    // useEffect(() => {
    //     const handleResize = () => {
    //         const vivaLogoSrc = document.querySelector('#vivaLogo');
    //         if(location.pathname !== '/' && !stickyMenuActive){
    //             vivaLogoSrc.src = DarkLogo;
    //         }else if(location.pathname !== '/' && stickyMenuActive){
    //             vivaLogoSrc.src = LightLogo;
    //         }else{
    //             vivaLogoSrc.src = LightLogo;
    //         }
    //     }
    //     window.addEventListener("resize", handleResize);
    //     return () => window.removeEventListener("resize", handleResize);
    // }, [stickyMenuActive]);

    return (
        <>
            <Link to={'/'} className='navbar-brand'>

                <img 
                    id="vivaLogo"
                    src={newImgSrc} 
                    alt=""
                />
            </Link>
        </>
    )
}

const NavMenu = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const [isNavSticky, setNavSticky] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1023);
    const [isTablet, setIsTablet] = useState(window.innerWidth < 1023);
    const [position, setPosition] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(true);
    const location = useLocation()
    let stickMenuActive;
    let toggleActive;

    useEffect(() => {
        window.addEventListener('resize', () => {
            const ismobile = window.innerWidth < 768;
            const istablet = window.innerWidth < 1024;
            if (ismobile !== isMobile) setIsMobile(ismobile);
            if (istablet !== isTablet) setIsTablet(istablet);
        }, false);
    }, [isMobile, isTablet]);


    /**Hide the Navigation Hamburger to Desktop STart */
    useEffect( () => {
        if(!isTablet){
            setIsNavExpanded(false);
            document.body.classList.remove('overflowYHidden');
        }
    });
    /**Hide the Navigation Hamburger to Desktop End */
    
    const rmNavExpanded = () => {
        if(isTablet){
            document.querySelector('.collapse.navbar-collapse').classList.remove('active');
            document.querySelector('.header-wrap>.navbar').classList.remove('hm-active');
            setIsNavExpanded(!isNavExpanded);
            document.body.classList.remove('overflowYHidden');
        }
     }

    if(isMobile && (isNavSticky && isNavExpanded)){ 
        toggleActive = 'hm-active';
        stickMenuActive = '';
    } else if(isMobile && (isNavSticky && !isNavExpanded)){
        toggleActive = '';
        stickMenuActive = 'sticky-nav';
    } else if(isMobile && (!isNavSticky && isNavExpanded)){ 
        toggleActive = 'hm-active';
        stickMenuActive = '';
    }else{
        //for sticky menu only
        stickMenuActive = (isNavSticky) ? 'sticky-nav' : '';
        toggleActive = '';
    }

    if(isNavExpanded){
        document.body.classList.add('overflowYHidden');
    }else{
        document.body.classList.remove('overflowYHidden');
    }

    const setSticky = () => {
        if(window.scrollY >= 50){
            setNavSticky(true);
        }else{
            setNavSticky(false);
        }
    }

    window.addEventListener('scroll', setSticky);

    /**Hide&Show on Scroll Down & Scroll Up Mobile to tablet */
    // useEffect(()=> {
    //     window.addEventListener("scroll", handleScroll);
    //     return () => window.removeEventListener("scroll", handleScroll);
    // });

    // const handleScroll = () => {
    //     const currentYOffset = window.pageYOffset;
    //     const visible = position > currentYOffset;

    //     setPosition(currentYOffset);
    //     setVisible(visible);
    // };

    // const menuVisibility = visible ? "visible" : "hidden";
    const menuVisibility = "visible";

    const NavLinkItem = ({to, children, ...props }) => {
        const resolvePath = useResolvedPath(to)
        const hashCheck = ( resolvePath.hash.length !== 0 ) ? resolvePath.hash : resolvePath.pathname;
        const isActive = useMatch({ path: hashCheck });

        return(
            <li className={isActive ? 'nav-item active': 'nav-item'}>
                {!resolvePath.hash ? (
                    <NavLink 
                        to={to}
                        {...props}
                        onClick={rmNavExpanded}
                    >
                        {children}
                    </NavLink>
                ) : (
                    <HashLink
                        smooth
                        to={to}
                        onClick={rmNavExpanded}
                    >{children}</HashLink>
                )}
            </li>
        )
    }

    return (
        <nav className={`navbar navbar-expand-lg navbar-light bg-light ${stickMenuActive} ${toggleActive} ${menuVisibility}`}>
            <div className='inner'>
                <NavBrand stickMenu={isNavSticky}/>
                <button 
                    className='navbar-toggler'
                    data-target='navbarDropdown' 
                    type='button'
                    onClick={() => {
                        setIsNavExpanded(!isNavExpanded)
                    }}
                >
                    <span className='navbar-toggler-icon'></span>
                </button>
                <div className={isNavExpanded ? "collapse navbar-collapse active" : "collapse navbar-collapse"} id='navbarDropdown'>
                    <ul className='navbar-nav mr-auto'>
                        <li className="nav-item close">
                            <span 
                                className="close-icon" 
                                onClick={() => {
                                    setIsNavExpanded(!isNavExpanded)
                                }}
                                type='button'
                            ></span>
                        </li>

                        <NavLinkItem to="/" >Home</NavLinkItem>
                        <NavLinkItem to="/#aboutviva" >About Viva</NavLinkItem>
                        <NavLinkItem to="/games" >Games</NavLinkItem>
                        <NavLinkItem to="/#contact" >Contact</NavLinkItem>

                        { location.pathname !== '/login' && 
                            <li className="nav-item sign-mobile-in">
                                <Link 
                                    to="/login" 
                                    className="btn"
                                    onClick={rmNavExpanded}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                > Client's Area</Link>
                            </li>}
                    </ul>
                    { location.pathname !== '/login' && 
                        <div className="sign-desk-in">
                            <Link 
                                to="/login" 
                                className="btn"
                                target="_blank"
                                rel="noopener noreferrer"
                            > Client's Area</Link>
                    </div>}
                    
                </div>
                
            </div>
        </nav>
    )
}

export default NavMenu;