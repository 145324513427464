import React, {useState, useEffect} from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

const GET_FIFTH_QUERY = gql `
    query HomePage {
        homeSettings {
            homeSettings {
                hpsFthGrp {
                    hpsFthContent
                    hpsFthId
                    hpsFthShowHideBtnSect
                    fieldGroupName
                    hpsFthShowHideButtons
                    hpsFthTitle
                    hpsFthProducts {
                        ... on Game {
                            id
                            featuredImage {
                                node {
                                    altText
                                    sourceUrl
                                }
                            }
                          slug
                          title
                          gamePostSettings {
                            gamePlayDemo
                          }
                        }
                    }
                }
            }
        }
    }
`;

const ProductSuiteSection = () => {
    const {loading, error, data} = useQuery(GET_FIFTH_QUERY);
    const HomeFifthFound = Boolean(data?.homeSettings.homeSettings.hpsFthGrp);

    return (
        <>
            {loading ? (
            <p className="d-none">Loading…</p>
            ) : error ? (
                <p>Error: {error.message}</p>
            ) : !HomeFifthFound ? (
                <p>Section not be found.</p>
            ) : (
                <FifthSecLayout section={data.homeSettings.homeSettings.hpsFthGrp}/>
            )}
        </>
    )
}


const FifthSecLayout = (props) => {
    if(props.section.hpsFthShowHideBtnSect !== 'yes'){
        return (
            <section className="fifth-section vs-home-sec" id={props.section.hpsFrthId}>
                <div className="inner">
                    <SectionHeader title={props.section.hpsFthTitle} desc={props.section.hpsFthContent} />
                    <SectionContent key={props} group={props.section.hpsFthProducts} button={props.section.hpsFthShowHideButtons}/>
                </div>
            </section>
        )
    }else{
        return null;
    }
}

const SectionHeader = (props) => {
    if(props.title !== null && props.desc !== null){
        return (
            <div className="vs-sec-header">
            {
                props.title ? (
                    <h1 className="flex flex-center sec-title txt-upper">{props.title}</h1>
                ) : null
            }
            {
                props.desc ? (
                    <span className="vs-sec-desc flex flex-center" 
                    dangerouslySetInnerHTML={{__html:props.desc}} />
                ) : null
            }
            </div>
        )
    }
}

const SectionContent = ({ group, button }) => {
    let btnWrp = ( button === 'yes') ? <SectionBTN /> : '';
    const openInNewTab = (url) => { window.open(url, '_blank', 'noreferrer'); }
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
    
    useEffect(() => {
        window.addEventListener('resize', () => {
            const ismobile = window.innerWidth < 1024;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false)
    }, [isMobile]);
    if(group !== null){
        return(
            <div className="vs-sec-content flex flex-center flex-column">
                <div className="vs-prod-grp flex">
                    {
                        group.map((prod, key) => {
                            return (
                                <>
                                { (isMobile) ? (
                                    
                                    <Link to={`/games/${prod.slug}`} rel='nofollow' >
                                        <div className="vs--prod-item" key={key}>
                                            { prod.featuredImage ? (
                                                <img
                                                    src={prod.featuredImage.node.sourceUrl}
                                                    alt={prod.featuredImage.node.altText} 
                                                />
                                            ) : null}
                                        </div>
                                    </Link>
                                ) : (
                                    <div className="vs--prod-item" key={key}>
                                    { prod.featuredImage ? (
                                            <img
                                                src={prod.featuredImage.node.sourceUrl}
                                                alt={prod.featuredImage.node.altText} 
                                            />
                                        ) : null}
                                        <div className="vs--hidden-prod">
                                            <div className="vs--hidden-btn flex flex-column justify-center">
                                                { prod.gamePostSettings.gamePlayDemo ? (
                                                    <button className="btn" onClick={() => openInNewTab(prod.gamePostSettings.gamePlayDemo)}>Play Demo</button>
                                                ) : null}
                                                <Link className="btn btn-transparent" rel="nofollow" to={`/games/${prod.slug}`}>view Details</Link>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                </>
                            )
                        })
                    }
                </div>
                <div className="btnWrap">
                    {btnWrp}
                </div>
            </div>
        )
    }else{
        return null;
    }
}

const SectionBTN = () => {
    return (
        <>
            <Link className="btn" to={`/games`} rel="nofollow">Explore our games!</Link>
        </>
    )
}

export default ProductSuiteSection;