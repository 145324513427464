import React from "react";
import { ApolloProvider } from "@apollo/client";
import Client from "./utils/DataConnection";
import MainComponent from './main';
import ReactGA from "react-ga4";


ReactGA.initialize("G-9BC195Q683");

function App() {

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     showLoader();
  //     loaderAddClass();
  //   }, 8000);
  //   return () => clearTimeout(timeout);
  // }, []);

  return (
    <div className="App">
      <ApolloProvider client={Client}>
        <MainComponent />
      </ApolloProvider>
    </div>
  ); 

}

export default App;
